import React from 'react';
import logo from './logo.svg';
import './App.css';

import Chatbot from '../src/component/bot';
function App() {
  return (
    <>
    <Chatbot/>
    </>
  
  );
}

export default App;
